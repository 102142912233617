<template>
    <!-- <Layout> -->
    <div class="px-1 mt-1" ref="wrapper">
        <div class="form-header">
            <h4 v-if="!viewMode">
                {{ viewMode ? $t('member view') : $t('member registration') }}
            </h4>
            <ul v-else class="nav nav-tabs custom-tabs">
                <li
                    class="nav-item"
                    v-for="s in section_options"
                    :key="s.value">
                    <a
                        :disabled="loading"
                        class="nav-link"
                        aria-current="page"
                        :href="s.value"
                        >{{ $t(s.name) }}</a
                    >
                </li>
            </ul>
            <div class="button-items">
                <button
                    v-if="viewMode"
                    class="btn btn-dark btn-lg fw-bold"
                    @click="convertToAgent()">
                    {{ $t('convert to agent') }}
                </button>
                <button
                    class="btn btn-danger btn-lg fw-bold"
                    @click.prevent="submitForm">
                    {{ $t('submit') }}
                </button>
            </div>
        </div>

        <div class="card form overflow-hidden">
            <div class="card-body">
                <LoadingTableForm
                    :tables="1"
                    :cols="4"
                    :rows="10"
                    class="table-bordered bg-white mb-0"
                    v-if="loading" />
                <b-form v-else>
                    <table
                        class="table table-bordered table-nowrap table-form"
                        id="basic-information">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th
                                    class="text-start"
                                    colspan="4"
                                    :class="{
                                        'bg-warning bg-soft':
                                            $route.hash ===
                                            '#basic-information',
                                    }">
                                    <div class="d-flex justify-content-between">
                                        {{ $t('account') }}
                                        <!-- <div class="form-check-success">
                                            <b-form-checkbox
                                                v-model="pl.status"
                                                switch
                                                value="active"
                                                unchecked-value="inactive">
                                                {{ $t(pl.status) }}
                                            </b-form-checkbox>
                                        </div> -->
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('type') }}
                                </th>
                                <td style="width: 35%">
                                    <div
                                        class="d-flex gap-2 mt-2"
                                        v-if="!viewMode">
                                        <b-form-radio
                                            :disabled="pl.type == referrer_type"
                                            name="type"
                                            value="offline"
                                            v-model="pl.type">
                                            {{ $t('offline') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            :disabled="pl.type == referrer_type"
                                            name="type"
                                            value="online"
                                            v-model="pl.type">
                                            {{ $t('online') }}
                                        </b-form-radio>
                                    </div>
                                    <div
                                        v-else
                                        class="d-flex align-items-center gap-1 mt-2">
                                        <i
                                            :class="`mdi ${
                                                pl.type == 'online'
                                                    ? 'mdi-web text-info'
                                                    : pl.type == 'offline'
                                                    ? 'mdi-storefront text-success'
                                                    : ''
                                            } fs-5`"></i>
                                        <span>{{ $t(pl.type) }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="viewMode">
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('high-level store') }}
                                </th>
                                <td colspan="3">
                                    <div class="d-flex gap-4 mt-2">
                                        <div
                                            v-for="r in pl.agent_upline"
                                            :key="r._id"
                                            class="td-username justify-content-center">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-secondary"
                                                @click="
                                                    goToTicketForm(
                                                        r.username,
                                                        r.role
                                                    )
                                                ">
                                                <i
                                                    class="bx bx-envelope bx-tada-hover fs-4"></i
                                            ></a>

                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    goToUserForm(r._id, r.role)
                                                "
                                                class="link-username"
                                                ><i
                                                    :class="`mdi ${
                                                        r.type == 'online'
                                                            ? 'mdi-web text-info'
                                                            : r.type ==
                                                              'offline'
                                                            ? 'mdi-storefront text-success'
                                                            : ''
                                                    } fs-5`"></i>

                                                <i
                                                    :class="`mdi ${
                                                        r.role == 'agent'
                                                            ? 'mdi-account-tie text-danger'
                                                            : r.role == 'player'
                                                            ? 'mdi-account-cowboy-hat text-primary'
                                                            : ''
                                                    } fs-5`"></i>
                                                <i
                                                    :class="`mdi mdi-numeric-${
                                                        r[r.role].level
                                                    }-box fs-5  level-${
                                                        r[r.role].level
                                                    }`"></i>
                                                {{ r.username }}</a
                                            >
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    <i
                                        :class="`mdi ${
                                            referrer_type == 'online'
                                                ? 'mdi-web text-info'
                                                : referrer_type == 'offline'
                                                ? 'mdi-storefront text-success'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi ${
                                            referrer_role == 'agent'
                                                ? 'mdi-account-tie text-danger'
                                                : referrer_role == 'player'
                                                ? 'mdi-account-cowboy-hat text-primary'
                                                : ''
                                        } fs-5`"></i
                                    >{{ $t('referrer') }}
                                </th>
                                <td>
                                    <div v-if="!viewMode">
                                        <input
                                            :readonly="viewMode"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    referrer_role == 'agent'
                                                        ? v$.pl.agent_id.$error
                                                        : v$.pl.player_id
                                                              .$error,
                                                'is-valid':
                                                    !viewMode &&
                                                    (referrer_role == 'agent'
                                                        ? pl.agent_id &&
                                                          !v$.pl.agent_id.$error
                                                        : pl.player_id &&
                                                          !v$.pl.player_id
                                                              .$error),
                                            }"
                                            :placeholder="`${$t(
                                                'enter referrer code or username'
                                            )}`"
                                            v-model="referrer_code"
                                            @input="getReferrer()" />
                                        <div
                                            v-if="
                                                v$.pl.agent_id.validReferrer
                                                    .$invalid
                                            "
                                            class="invalid-feedback">
                                            {{
                                                $t(
                                                    v$.pl.agent_id.validReferrer
                                                        .$message
                                                )
                                            }}
                                        </div>
                                        <div
                                            v-if="
                                                v$.pl.player_id.validReferrer
                                                    .$invalid
                                            "
                                            class="invalid-feedback">
                                            {{
                                                $t(
                                                    v$.pl.player_id
                                                        .validReferrer.$message
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            class="d-flex align-items-center mt-2"
                                            v-if="pl.agent_id">
                                            <i
                                                class="mdi mdi-account-tie text-danger fs-5"></i>
                                            <i
                                                :class="`mdi mdi-numeric-${pl.agent_referrer.agent.level}-box fs-5  level-${pl.agent_referrer.agent.level}`"></i>
                                            <span class="mb-0 lh-1">
                                                {{ pl.agent_referrer.username }}
                                            </span>
                                        </div>
                                        <div
                                            class="d-flex align-items-center mt-2"
                                            v-else-if="pl.player_id">
                                            <i
                                                class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                            <i
                                                :class="`mdi mdi-numeric-${pl.player_referrer.player.level}-box fs-5  level-${pl.player_referrer.player.level}`"></i>
                                            <span class="mb-0 lh-1">
                                                {{
                                                    pl.player_referrer.username
                                                }}
                                            </span>
                                        </div>
                                        <div v-else class="mt-2">
                                            <span class="mb-0 lh-1 text-danger">
                                                {{ $t('no referrer') }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('status') }}
                                </th>
                                <td tyle="width: 35%">
                                    <multiselect
                                        :placeholder="`${$t('select type')}`"
                                        v-model="pl.status"
                                        :options="status_options"
                                        label="name"
                                        :can-deselect="false"
                                        :can-clear="false">
                                    </multiselect>
                                </td>
                            </tr>
                            <tr>
                                <th
                                    class="bg-light bg-soft"
                                    style="width: 15%"
                                    v-if="viewMode">
                                    {{ $t('registration date') }}
                                </th>
                                <td style="width: 35%" v-if="viewMode">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control"
                                        :value="dateYmD(pl.created_at, true)" />
                                </td>

                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('level') }}
                                </th>
                                <td style="width: 35%">
                                    <multiselect
                                        :placeholder="`${$t('select level')}`"
                                        v-model="pl.player.level"
                                        :options="level_options"
                                        label="name"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.level.$error,
                                        }">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">
                                                <i
                                                    :class="`mdi mdi-numeric-${value.value}-box level-${value.value} fs-4`"></i>
                                                {{
                                                    $t('level') +
                                                    ' ' +
                                                    value.value
                                                }}</span
                                            >
                                        </template>
                                        <template v-slot:option="{ option }">
                                            <i
                                                :class="`mdi mdi-numeric-${option.value}-box level-${option.value} fs-4`"></i>
                                            {{
                                                $t('level') + ' ' + option.value
                                            }}
                                        </template>
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .level.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('username') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        autocomplete="off"
                                        :readonly="viewMode"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': v$.pl.username.$error,
                                            'is-valid':
                                                !viewMode &&
                                                pl.username &&
                                                !v$.pl.username.$error,
                                        }"
                                        :placeholder="`${$t('username')}`"
                                        v-model="pl.username"
                                        @input="getUsernames()" />
                                    <div
                                        v-if="
                                            v$.pl.username.available.$invalid &&
                                            !v$.pl.username.required.$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.username.available
                                                    .$message
                                            )
                                        }}
                                    </div>
                                    <div
                                        v-if="v$.pl.username.required.$invalid"
                                        class="invalid-feedback">
                                        {{
                                            $t(v$.pl.username.required.$message)
                                        }}
                                    </div>
                                    <div
                                        v-if="v$.pl.username.minLength.$invalid"
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.username.minLength
                                                    .$message
                                            )
                                        }}
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('name') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        class="form-control disabled"
                                        :placeholder="$t(`name`)"
                                        v-model="pl.name"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.pl.name.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.name
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('password') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-1 align-items-start">
                                        <div class="flex-fill">
                                            <input
                                                autocomplete="new-password"
                                                :type="
                                                    show_password
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                class="form-control"
                                                :placeholder="$t(`password`)"
                                                v-model="pl.password"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.password.$error,
                                                }" />

                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .password.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex-fill">
                                            <input
                                                type="password"
                                                class="form-control"
                                                :placeholder="
                                                    $t(`confirm password`)
                                                "
                                                v-model="pl.confirm_password"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.confirm_password
                                                            .$error,
                                                }" />
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .confirm_password.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <button
                                            v-if="viewMode"
                                            class="btn btn-outline-light"
                                            @click.prevent="
                                                show_password = !show_password
                                            ">
                                            <i
                                                :class="`mdi ${
                                                    show_password
                                                        ? 'mdi-eye-off-outline'
                                                        : 'mdi-eye-outline'
                                                }`"></i></button
                                        ><button
                                            v-if="viewMode"
                                            class="btn btn-outline-info"
                                            @click.prevent="getPassword()">
                                            {{ $t('view password') }}
                                        </button>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('charging/exchange password') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-1 align-items-start">
                                        <div class="flex-fill">
                                            <input
                                                :type="
                                                    show_pin
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'charging/exchange password'
                                                    )
                                                "
                                                maxlength="4"
                                                v-model="pl.pin"
                                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13; "
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.pin.$error,
                                                }" />
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .pin.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <button
                                            v-if="viewMode"
                                            class="btn btn-outline-light"
                                            @click.prevent="
                                                show_pin = !show_pin
                                            ">
                                            <i
                                                :class="`mdi ${
                                                    show_pin
                                                        ? 'mdi-eye-off-outline'
                                                        : 'mdi-eye-outline'
                                                }`"></i>
                                        </button>
                                        <button
                                            v-if="viewMode"
                                            class="btn btn-outline-info"
                                            @click.prevent="getPin()">
                                            {{ $t('view password') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('email address') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`email address`)"
                                        v-model="pl.email"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.pl.email.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.email
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('member type') }}
                                </th>
                                <td style="width: 35%">
                                    <multiselect
                                        :placeholder="`${$t('select type')}`"
                                        v-model="pl.player.type"
                                        :options="type_options"
                                        label="name"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.type.$error,
                                        }">
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .type.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('messenger') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`messenger`)" />
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('class') }}
                                </th>
                                <td style="width: 35%">
                                    <multiselect
                                        :placeholder="`${$t('select class')}`"
                                        v-model="pl.player.class"
                                        :options="class_options"
                                        label="name"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.class.$error,
                                        }">
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .class.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('balance') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control"
                                        :value="
                                            numberStringDecimal(
                                                pl.player.assets.balance
                                            )
                                        " />
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('behavior') }}
                                </th>
                                <td style="width: 35%">
                                    <multiselect
                                        :placeholder="`${$t(
                                            'select behavior'
                                        )}`"
                                        v-model="pl.player.behavior"
                                        :options="behavior_options"
                                        label="name"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.behavior.$error,
                                        }">
                                    </multiselect>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .behavior.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('points') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control"
                                        :value="
                                            numberStringDecimal(
                                                pl.player.assets.points
                                            )
                                        " />
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('betting class') }}
                                </th>
                                <td style="width: 35%">
                                    <multiselect
                                        :placeholder="`${$t(
                                            'select betting class'
                                        )}`"
                                        :options="bet_class_options"
                                        label="name"
                                        :can-deselect="false"
                                        :can-clear="false">
                                    </multiselect>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('mobile number') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`mobile number`)"
                                        v-model="pl.mobile_number"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.mobile_number.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl
                                            .mobile_number.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('note') }}
                                </th>
                                <td style="width: 35%" colspan="3">
                                    <QuillEditor
                                        contentType="html"
                                        :placeholder="$t('enter content here')"
                                        v-model:content="pl.note" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        v-if="['all', 'online'].includes(player_mode)"
                        class="table table-bordered table-nowrap table-form handler-table">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="4">
                                    {{ $t('handler bank') }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td class="p-0">
                                    <table class="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-start"
                                                    colspan="2"
                                                    :class="`${
                                                        pl.handler_bank.physical
                                                            .default
                                                            ? 'table-primary'
                                                            : ''
                                                    }`">
                                                    <div
                                                        class="d-flex justify-content-between">
                                                        <b-form-radio
                                                            name="default-handler-bank"
                                                            v-model="
                                                                pl.handler_bank
                                                                    .physical
                                                                    .default
                                                            ">
                                                            <span
                                                                class="fw-bold">
                                                                {{
                                                                    $t(
                                                                        'physical'
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    pl
                                                                        .handler_bank
                                                                        .physical
                                                                        .default
                                                                "
                                                                class="text-muted ms-1"
                                                                >({{
                                                                    $t(
                                                                        'default'
                                                                    )
                                                                }})</span
                                                            >
                                                        </b-form-radio>
                                                        <b-form-checkbox
                                                            switch
                                                            v-model="
                                                                pl.handler_bank
                                                                    .physical
                                                                    .enabled
                                                            ">
                                                            {{
                                                                pl.handler_bank
                                                                    .physical
                                                                    .enabled
                                                                    ? $t(
                                                                          'enabled'
                                                                      )
                                                                    : $t(
                                                                          'disabled'
                                                                      )
                                                            }}
                                                        </b-form-checkbox>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t('bank name') }}
                                                </th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`bank name`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .physical
                                                                .bank_name
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .physical
                                                                    .bank_name
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .physical.bank_name
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account holder') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account holder`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .physical
                                                                .account_holder
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .physical
                                                                    .account_holder
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .physical
                                                            .account_holder
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account number') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account number`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .physical
                                                                .account_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .physical
                                                                    .account_number
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .physical
                                                            .account_number
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td class="p-0">
                                    <table class="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-start"
                                                    colspan="2"
                                                    :class="`${
                                                        pl.handler_bank.virtual
                                                            .default
                                                            ? 'table-primary'
                                                            : ''
                                                    }`">
                                                    <div
                                                        class="d-flex justify-content-between">
                                                        <b-form-radio
                                                            name="default-handler-bank"
                                                            v-model="
                                                                pl.handler_bank
                                                                    .virtual
                                                                    .default
                                                            ">
                                                            <span
                                                                class="fw-bold">
                                                                {{
                                                                    $t(
                                                                        'virtual'
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    pl
                                                                        .handler_bank
                                                                        .virtual
                                                                        .default
                                                                "
                                                                class="text-muted ms-1"
                                                                >({{
                                                                    $t(
                                                                        'default'
                                                                    )
                                                                }})</span
                                                            >
                                                        </b-form-radio>
                                                        <b-form-checkbox
                                                            switch
                                                            v-model="
                                                                pl.handler_bank
                                                                    .virtual
                                                                    .enabled
                                                            ">
                                                            {{
                                                                pl.handler_bank
                                                                    .virtual
                                                                    .enabled
                                                                    ? $t(
                                                                          'enabled'
                                                                      )
                                                                    : $t(
                                                                          'disabled'
                                                                      )
                                                            }}
                                                        </b-form-checkbox>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t('bank name') }}
                                                </th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`bank name`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .virtual
                                                                .bank_name
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .virtual
                                                                    .bank_name
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .virtual.bank_name
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account holder') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account holder`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .virtual
                                                                .account_holder
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .virtual
                                                                    .account_holder
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .virtual
                                                            .account_holder
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account number') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account number`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .virtual
                                                                .account_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .virtual
                                                                    .account_number
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .virtual
                                                            .account_number
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered table-nowrap table-form">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="4">
                                    <div
                                        class="d-flex justify-content-start gap-3">
                                        {{ $t('commission') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.commissions.enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('commission type') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-5 mt-2">
                                        <b-form-radio
                                            name="commission type"
                                            value="bet"
                                            v-model="
                                                pl.player.commissions.type
                                            ">
                                            {{ $t('bet') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="commission type"
                                            value="losing"
                                            v-model="
                                                pl.player.commissions.type
                                            ">
                                            {{ $t('losing') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <th
                                    class="bg-light bg-soft"
                                    style="width: 15%"
                                    v-if="
                                        pl.player.commissions.type == 'losing'
                                    ">
                                    <div class="d-flex justify-content-between">
                                        {{ $t('losing') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.commissions.losing
                                                    .enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td
                                    v-if="
                                        pl.player.commissions.type == 'losing'
                                    ">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.player.commissions.losing
                                                .enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.commissions.losing
                                                    .value.$error,
                                        }"
                                        v-model:value="
                                            pl.player.commissions.losing.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .commissions.losing.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <b-collapse :visible="pl.player.commissions.type == 'bet'">
                        <div class="row">
                            <div class="col-md-4">
                                <table
                                    class="table table-bordered table-nowrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                <div
                                                    class="d-flex justify-content-start gap-3">
                                                    {{ $t('powerball') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                {{ $t('type') }}
                                            </th>
                                            <td style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    <b-form-radio
                                                        name="some-radios1"
                                                        value="single"
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball.type
                                                        ">
                                                        {{ $t('single') }}
                                                    </b-form-radio>
                                                    <b-form-radio
                                                        name="some-radios1"
                                                        value="multiple"
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball.type
                                                        ">
                                                        {{ $t('multiple') }}
                                                    </b-form-radio>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.player.commissions.minigames
                                                    .powerball.type == 'single'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between justify-content-between">
                                                    {{ $t('single') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .single.enabled
                                                        "></b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.minigames.powerball.single.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.minigames.powerball.single.value.$error,}"
                                                        v-model="pl.player.commissions.minigames.powerball.single.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .minigames.powerball.single.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .minigames.powerball
                                                            .single.enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .single.value
                                                                .$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .minigames.powerball
                                                            .single.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .minigames.powerball
                                                        .single.value.$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.player.commissions.minigames
                                                    .powerball.type ==
                                                'multiple'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('multiple-single') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.single
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.minigames.powerball.multiple.single.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.minigames.powerball.multiple.single.value.$error,}"
                                                        v-model="pl.player.commissions.minigames.powerball.multiple.single.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .minigames.powerball.multiple.single.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .minigames.powerball
                                                            .multiple.single
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.single
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .minigames.powerball
                                                            .multiple.single
                                                            .value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .minigames.powerball
                                                        .multiple.single.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.player.commissions.minigames
                                                    .powerball.type ===
                                                'multiple'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('multiple-double') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.double
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.minigames.powerball.multiple.double.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.minigames.powerball.multiple.double.value.$error,}"
                                                        v-model="pl.player.commissions.minigames.powerball.multiple.double.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .minigames.powerball.multiple.double.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .minigames.powerball
                                                            .multiple.double
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.double
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .minigames.powerball
                                                            .multiple.double
                                                            .value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .minigames.powerball
                                                        .multiple.double.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.player.commissions.minigames
                                                    .powerball.type ===
                                                'multiple'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('multiple-triple') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.triple
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.minigames.powerball.multiple.triple.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.minigames.powerball.multiple.triple.value.$error,}"
                                                        v-model="pl.player.commissions.minigames.powerball.multiple.triple.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .minigames.powerball.multiple.triple.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .minigames.powerball
                                                            .multiple.triple
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.triple
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .minigames.powerball
                                                            .multiple.triple
                                                            .value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .minigames.powerball
                                                        .multiple.triple.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-4">
                                <table
                                    class="table table-bordered table-nowrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                {{ $t('casino') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('baccarat') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .casino.table
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.casino.table.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.casino.table.value.$error,}"
                                                        v-model="pl.player.commissions.casino.table.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .casino.table.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>


                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .casino.table
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .casino.table
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .casino.table.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .casino.table.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('slots') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .casino.slots
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.casino.slots.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.casino.slots.value.$error,}"
                                                        v-model="pl.player.commissions.casino.slots.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .casino.slots.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .casino.slots
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .casino.slots
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .casino.slots.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .casino.slots.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('losing') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .casino.losing
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.player.commissions.casino.losing.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.player.commissions.casino.losing.value.$error,}"
                                                        v-model="pl.player.commissions.casino.losing.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.player.commissions
                                                            .casino.losing.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .casino.losing
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .casino.losing
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .casino.losing.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .casino.losing.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-4">
                                <table
                                    class="table table-bordered table-nowrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                {{ $t('safety') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('baccarat') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .safety.table
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .safety.table
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .safety.table
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .safety.table.value
                                                    "
                                                    :options="{
                                                        precision: 0,
                                                        prefix: '',
                                                        suffix: '',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .safety.table.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('slots') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.player
                                                                .commissions
                                                                .safety.slots
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <VueNumberFormat
                                                    :disabled="
                                                        !pl.player.commissions
                                                            .safety.slots
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.player
                                                                .commissions
                                                                .safety.slots
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.player.commissions
                                                            .safety.slots.value
                                                    "
                                                    :options="{
                                                        precision: 0,
                                                        prefix: '',
                                                        suffix: '',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.player.commissions
                                                        .safety.slots.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-collapse>
                    <!-- <table
                        v-if="['all', 'online'].includes(player_mode)"
                        class="table table-bordered table-nowrap table-form"
                        id="rebate">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="6">
                                    {{ $t('rebate') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th
                                    class="bg-light bg-soft"
                                    style="width: 15%"
                                    colspan="2">
                                    <div
                                        class="d-flex justify-content-between gap-2 mx-3">
                                        {{ $t('casino') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.rebate.casino.enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    <div
                                        class="d-flex justify-content-between gap-2 mx-3">
                                        {{ $t('table') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.rebate.casino.table
                                                    .enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td style="width: 15%">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.player.rebate.casino.table
                                                .enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.rebate.casino.table
                                                    .value.$error,
                                        }"
                                        v-model:value="
                                            pl.player.rebate.casino.table.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .rebate.casino.table.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    <div
                                        class="d-flex justify-content-between gap-2 mx-3">
                                        {{ $t('slot') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.rebate.casino.slots
                                                    .enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td style="width: 15%">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.player.rebate.casino.slots
                                                .enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.rebate.casino.slots
                                                    .value.$error,
                                        }"
                                        v-model:value="
                                            pl.player.rebate.casino.slots.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .rebate.casino.slots.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    <div
                                        class="d-flex justify-content-between mx-3">
                                        {{ $t('sports') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.rebate.sports.enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td style="width: 15%">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.player.rebate.sports.enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.rebate.sports.value
                                                    .$error,
                                        }"
                                        v-model:value="
                                            pl.player.rebate.sports.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .rebate.sports.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 20%">
                                    <div
                                        class="d-flex justify-content-between mx-3">
                                        {{ $t('minigame') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.rebate.minigames
                                                    .enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td style="width: 15%">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.player.rebate.minigames.enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.rebate.minigames
                                                    .value.$error,
                                        }"
                                        v-model:value="
                                            pl.player.rebate.minigames.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .rebate.minigames.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    <div
                                        class="d-flex justify-content-between mx-3">
                                        {{ $t('losing') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.player.rebate.losing.enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td style="width: 15%">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.player.rebate.losing.enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.player.rebate.losing.value
                                                    .$error,
                                        }"
                                        v-model:value="
                                            pl.player.rebate.losing.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.player
                                            .rebate.losing.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
                </b-form>
                <table
                    class="table table-bordered table-nowrap table-form"
                    id="money-management"
                    v-if="viewMode">
                    <thead>
                        <tr class="fs-5 bg-light bg-soft">
                            <th
                                class="text-start"
                                colspan="6"
                                :class="{
                                    'bg-warning bg-soft':
                                        $route.hash === '#money-management',
                                }">
                                {{ $t('Money, points increase or decrease') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('balance') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <td style="width: 20%">
                                <VueNumberFormat
                                    :disabled="pl_balance.loading"
                                    class="form-control text-end rounded-end"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_balance.amount.$error,
                                    }"
                                    v-model:value="pl_balance.amount"
                                    :options="{
                                        precision: 0,
                                        prefix: '',
                                        suffix: '',
                                        thousand: ',',
                                        acceptNegative: false,
                                        isInteger: true,
                                    }"></VueNumberFormat>
                                <div
                                    v-for="(item, index) in v$.pl_balance.amount
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                            <!-- <td style="width: 50%">
                                <input
                                    :disabled="pl_balance.loading"
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t(`enter your note`)"
                                    v-model="pl_balance.note"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_balance.note.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl_balance.note
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td> -->
                            <td class="text-center">
                                <b-spinner
                                    type="grow"
                                    variant="dark"
                                    role="status"
                                    medium
                                    v-if="pl_balance.loading"></b-spinner>
                                <div class="button-items" v-else>
                                    <button
                                        class="btn btn-outline-success"
                                        @click="submitAsset('balance', 'add')">
                                        {{ $t('add') }}
                                    </button>
                                    <button
                                        class="btn btn-outline-danger"
                                        @click="
                                            submitAsset('balance', 'subtract')
                                        ">
                                        {{ $t('subtract') }}
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('points') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <td style="width: 20%">
                                <VueNumberFormat
                                    class="form-control text-end rounded-end"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_points.amount.$error,
                                    }"
                                    v-model:value="pl_points.amount"
                                    :options="{
                                        precision: 0,
                                        prefix: '',
                                        suffix: '',
                                        thousand: ',',
                                        acceptNegative: false,
                                        isInteger: true,
                                    }"></VueNumberFormat>
                                <div
                                    v-for="(item, index) in v$.pl_points.amount
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                            <!-- <td style="width: 50%">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t(`enter your note`)"
                                    v-model="pl_points.note"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_points.note.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl_points.note
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td> -->
                            <td class="text-center">
                                <b-spinner
                                    type="grow"
                                    variant="dark"
                                    role="status"
                                    medium
                                    v-if="pl_points.loading"></b-spinner>
                                <div class="button-items" v-else>
                                    <button
                                        class="btn btn-outline-success"
                                        @click="submitAsset('points', 'add')">
                                        {{ $t('add') }}
                                    </button>
                                    <button
                                        class="btn btn-outline-danger"
                                        @click="
                                            submitAsset('points', 'subtract')
                                        ">
                                        {{ $t('subtract') }}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr v-show="viewMode" />

                <div class="row" id="money-logs" v-if="viewMode">
                    <div class="col-md-6 pe-1">
                        <div class="small-date-filter" v-if="!deposit_loading">
                            <datepicker
                                v-model="deposit_filters.from"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>

                            <span>~</span>
                            <datepicker
                                v-model="deposit_filters.to"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>
                        </div>
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="4"
                                :rows="6"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="deposit_loading" />

                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th
                                            class="text-start"
                                            colspan="5"
                                            :class="{
                                                'bg-warning bg-soft':
                                                    $route.hash ===
                                                    '#money-logs',
                                            }">
                                            {{ $t('deposit') }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('created at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in deposit_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 150px"
                                v-show="
                                    !deposit_data.list.length &&
                                    !deposit_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <Pagination
                            :data="deposit_data"
                            @emitPage="initDeposit"
                            :small="true" />
                    </div>
                    <div class="col-md-6 ps-1">
                        <div class="small-date-filter" v-if="!withdraw_loading">
                            <datepicker
                                :vertical="true"
                                v-model="withdraw_filters.from"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>

                            <span>~</span>
                            <datepicker
                                v-model="withdraw_filters.to"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>
                        </div>
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="3"
                                :rows="6"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="withdraw_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0"
                                id="withdraw">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th
                                            class="text-start"
                                            colspan="5"
                                            :class="{
                                                'bg-warning bg-soft':
                                                    $route.hash ===
                                                    '#money-logs',
                                            }">
                                            {{ $t('withdraw') }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('created at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in withdraw_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 150px"
                                v-show="
                                    !withdraw_data.list.length &&
                                    !withdraw_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <Pagination
                            :data="withdraw_data"
                            @emitPage="initWithdraw"
                            :small="true" />
                    </div>
                    <div class="col-md-12 pt-1">
                        <div class="small-date-filter" v-if="!bet_loading">
                            <select
                                class="form-control form-control-sm w-50"
                                v-model="bet_filters.game">
                                <option value="">
                                    {{ $t('all') }}
                                </option>
                                <option value="slot">
                                    {{ $t('slot') }}
                                </option>
                                <option value="table">
                                    {{ $t('table') }}
                                </option>
                            </select>
                            <datepicker
                                :vertical="true"
                                v-model="bet_filters.from"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>
                            <span>~</span>
                            <datepicker
                                v-model="bet_filters.to"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>
                        </div>
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="5"
                                :rows="6"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="bet_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0"
                                id="bet-history">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th
                                            class="text-start"
                                            colspan="6"
                                            :class="{
                                                'bg-warning bg-soft':
                                                    $route.hash ===
                                                    '#bet-history',
                                            }">
                                            {{ $t('bet history') }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('created at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('vendor') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('game') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('win amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="r in bet_data.list" :key="r._id">
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.title }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.game }}
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-end">
                                            {{
                                                r.win_amount
                                                    ? numberString(r.win_amount)
                                                    : ''
                                            }}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-primary':
                                                        r.status == 'pending',
                                                    'text-danger':
                                                        r.status == 'win',
                                                    'text-info':
                                                        r.status == 'lose',
                                                    'text-secondary':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 150px"
                                v-show="!bet_data.list.length && !bet_loading">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <Pagination
                            :data="bet_data"
                            @emitPage="initBet"
                            :small="true" />
                    </div>
                    <div class="col-md-6 pe-1 pt-1">
                        <div
                            class="small-date-filter"
                            v-if="!activity_log_loading">
                            <datepicker
                                v-model="activity_log_filters.from"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>

                            <span>~</span>
                            <datepicker
                                v-model="activity_log_filters.to"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>
                        </div>
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="4"
                                :rows="6"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="activity_log_loading" />

                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0"
                                id="activity-logs">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th
                                            class="text-start"
                                            colspan="5"
                                            :class="{
                                                'bg-warning bg-soft':
                                                    $route.hash ===
                                                    '#activity-logs',
                                            }">
                                            {{ $t('activity logs') }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('created at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('function') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('browser') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('ip address') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in activity_log_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.function }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.agent.browser }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.ip }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 150px"
                                v-show="
                                    !activity_log_data.list.length &&
                                    !activity_log_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <Pagination
                            :data="activity_log_data"
                            @emitPage="initActivityLogs"
                            :small="true" />
                    </div>
                    <div class="col-md-6 ps-1 pt-1">
                        <div
                            class="small-date-filter"
                            v-if="!point_log_loading">
                            <datepicker
                                :vertical="true"
                                v-model="point_log_filters.from"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>

                            <span>~</span>
                            <datepicker
                                v-model="point_log_filters.to"
                                :first-day-of-week="0"
                                lang="en"
                                confirm
                                class="form-control form-control-sm bg-white"
                                :disabled="loading"></datepicker>
                        </div>
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="3"
                                :rows="6"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="point_log_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0"
                                id="point-logs">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th
                                            class="text-start"
                                            colspan="5"
                                            :class="{
                                                'bg-warning bg-soft':
                                                    $route.hash ===
                                                    '#point-logs',
                                            }">
                                            {{ $t('point logs') }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('created at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('type') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('initial value') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('updated value') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in point_log_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.type }}
                                        </td>
                                        <td class="text-end">
                                            {{
                                                numberStringDecimal(
                                                    r.initial_value
                                                )
                                            }}
                                        </td>
                                        <td class="text-end">
                                            {{ numberStringDecimal(r.amount) }}
                                        </td>
                                        <td class="text-end">
                                            {{
                                                numberStringDecimal(
                                                    r.updated_value
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 150px"
                                v-show="
                                    !point_log_data.list.length &&
                                    !point_log_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <Pagination
                            :data="point_log_data"
                            @emitPage="initPointLogs"
                            :small="true" />
                    </div>
                </div>
                <!-- <hr /> -->
            </div>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import Pagination from '../../../components/pagination.vue';
import LoadingTable from '@/components/loading-table';
import LoadingTableForm from '@/components/loading-table-form';
import Swal from 'sweetalert2';
import ui from '@/mixins/ui';

import {
    required,
    requiredIf,
    minLength,
    maxLength,
    minValue,
    maxValue,
    sameAs,
    email,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import convert from '../../../mixins/convert';
import string from '../../../mixins/string';

import Multiselect from '@vueform/multiselect';
// custom utilities
const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const validPassword = (value) => {
    const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]+$/;
    return !params.id ? helpers.req(value) && regex.test(value) : true;
};
const alphaNumericOnly = (value) => {
    // const regex = /^(?:[a-zA-Z0-9][a-zA-Z0-9_]*[a-zA-Z0-9]|)$/;
    const regex = /^[A-Za-z0-9]+$/;
    return !params.id ? helpers.req(value) && regex.test(value) : true;
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        PageHeader,
        Pagination,
        Multiselect,
        LoadingTable,
        LoadingTableForm,
        Datepicker,
    },
    mixins: [datetime, number, convert, string, ui],
    data() {
        return {
            title: 'Player Form',
            submitted: false,
            _id: this.$route.query.id,
            // REAL DATA
            folder: [],
            games: [],
            pl: {
                created_at: null,
                agent_id: null,
                player_id: null,
                type: 'offline',
                username: null,
                status: 'active',
                password: null,
                confirm_password: null,
                pin: null,
                name: null,
                email: null,
                mobile_number: null,
                agent: {
                    referral_code: '',
                },
                player: {
                    level: 1,
                    type: 'normal',
                    class: 'normal',
                    behavior: 'normal',
                    assets: {
                        balance: 0,
                        points: 0,
                    },
                    commissions: {
                        type: 'bet',
                        enabled: true,
                        losing: {
                            value: 0,
                            enabled: false,
                        },
                        minigames: {
                            powerball: {
                                type: 'single',
                                enabled: false,
                                single: {
                                    value: 0,
                                    enabled: false,
                                },
                                multiple: {
                                    single: {
                                        value: 0,
                                        enabled: false,
                                    },
                                    double: {
                                        value: 0,
                                        enabled: false,
                                    },
                                    triple: {
                                        value: 0,
                                        enabled: false,
                                    },
                                },
                            },
                        },
                        casino: {
                            table: {
                                value: 0,
                                enabled: false,
                            },
                            slots: {
                                value: 0,
                                enabled: false,
                            },
                            losing: {
                                value: 0,
                                enabled: false,
                            },
                        },
                        safety: {
                            table: {
                                value: 0,
                                enabled: false,
                            },
                            slots: {
                                value: 0,
                                enabled: false,
                            },
                        },
                    },
                    rebate: {
                        casino: {
                            enabled: false,
                            table: {
                                value: 0,
                                enabled: false,
                            },
                            slots: {
                                value: 0,
                                enabled: false,
                            },
                        },
                        sports: {
                            value: 0,
                            enabled: false,
                        },
                        minigames: {
                            value: 0,
                            enabled: false,
                        },
                        losing: {
                            value: 0,
                            enabled: false,
                        },
                    },
                },
                agent_referrer: {
                    agent: {
                        level: null,
                    },
                    name: null,
                    username: null,
                },
                player_referrer: {
                    player: {
                        level: null,
                    },
                    name: null,
                    username: null,
                },
                agent_upline: [],
                note: null,
                customer_bank: {
                    physical: {
                        bank_name: null,
                        account_number: null,
                        account_holder: null,
                    },
                    virtual: {
                        bank_name: null,
                        account_number: null,
                        account_holder: null,
                    },
                },
                handler_bank: {
                    physical: {
                        enabled: false,
                        default: true,
                        bank_name: null,
                        account_number: null,
                        account_holder: null,
                    },
                    virtual: {
                        enabled: false,
                        default: false,
                        bank_name: null,
                        account_number: null,
                        account_holder: null,
                    },
                },
            },
            pl_origin: {
                password: null,
                confirm_password: null,
            },
            pl_points: {
                loading: false,
                amount: null,
                note: null,
            },
            pl_balance: {
                loading: false,
                amount: null,
                note: null,
            },
            show_password: false,
            show_pin: false,
            current_password: null,
            status_options: [
                // { name: this.$t('all'), value: '' },
                { name: this.$t('active'), value: 'active' },
                { name: this.$t('inactive'), value: 'inactive' },
                { name: this.$t('pending'), value: 'pending' },
            ],
            level_options: [
                { name: this.$t('level 1'), value: 1 },
                { name: this.$t('level 2'), value: 2 },
                { name: this.$t('level 3'), value: 3 },
                { name: this.$t('level 4'), value: 4 },
                { name: this.$t('level 5'), value: 5 },
                { name: this.$t('level 6'), value: 6 },
                { name: this.$t('level 7'), value: 7 },
                { name: this.$t('level 8'), value: 8 },
                { name: this.$t('level 9'), value: 9 },
                { name: this.$t('level 10'), value: 10 },
            ],
            type_options: [
                { name: this.$t('normal'), value: 'normal' },
                { name: this.$t('demo'), value: 'demo' },
            ],
            class_options: [
                { name: this.$t('new'), value: 'new' },
                { name: this.$t('normal'), value: 'normal' },
                { name: this.$t('vip'), value: 'vip' },
            ],
            behavior_options: [
                { name: this.$t('conservative'), value: 'conservative' },
                { name: this.$t('normal'), value: 'normal' },
                { name: this.$t('aggressive'), value: 'aggressive' },
                { name: this.$t('threat'), value: 'threat' },
            ],
            minigame_options: [
                { name: this.$t('evolution'), value: 'evolution' },
                { name: this.$t('hotel casino'), value: 'hotel casino' },
                { name: this.$t('powerball'), value: 'powerball' },
                { name: this.$t('power ladder'), value: 'power ladder' },
            ],
            bet_class_options: [
                { name: this.$t('sports unstable'), value: 'sports unstable' },
                { name: this.$t('live unstable'), value: 'live unstable' },
                { name: this.$t('full unstable'), value: 'full unstable' },
                { name: this.$t('full stable'), value: 'full stable' },
            ],
            folder_options: [
                { name: this.$t('one folder'), value: 1 },
                { name: this.$t('two folder'), value: 2 },
            ],
            section_options: [
                {
                    name: this.$t('basic information'),
                    value: '#basic-information',
                },
                { name: this.$t('bet history'), value: '#bet-history' },
                { name: this.$t('money logs'), value: '#money-logs' },
                { name: this.$t('point logs'), value: '#point-logs' },
                { name: this.$t('activity logs'), value: '#activity-logs' },
            ],
            deposit_loading: false,
            deposit_filters: {
                from: new Date(),
                to: new Date(),
            },
            deposit_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            withdraw_loading: false,
            withdraw_filters: {
                from: new Date(),
                to: new Date(),
            },
            withdraw_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            bet_loading: false,
            bet_filters: {
                from: new Date(),
                to: new Date(),
                game: '',
            },
            activity_log_loading: false,
            activity_log_filters: {
                from: new Date(),
                to: new Date(),
            },
            activity_log_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            point_log_loading: false,
            point_log_filters: {
                from: new Date(),
                to: new Date(),
            },
            point_log_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            bet_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            referrer_ids: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
            agents_options: [],
            level_options: [
                { name: this.$t('level 1'), value: 1 },
                { name: this.$t('level 2'), value: 2 },
                { name: this.$t('level 3'), value: 3 },
                { name: this.$t('level 4'), value: 4 },
                { name: this.$t('level 5'), value: 5 },
                { name: this.$t('level 6'), value: 6 },
                { name: this.$t('level 7'), value: 7 },
                { name: this.$t('level 8'), value: 8 },
                { name: this.$t('level 9'), value: 9 },
                { name: this.$t('level 10'), value: 10 },
            ],
            referral_code_user: {
                id: null,
                username: null,
                role: null,
            },
            loading: false,
            user_id: null,
            referrer_code: null,
            referrer_id: null,
            referrer_username: null,
            referrer_role: null,
            referrer_type: null,
            check_referrer_id: null,
        };
    },
    validations() {
        return {
            pl: {
                agent_id: {
                    // requiredIf: helpers.withMessage(
                    //     'required',
                    //     requiredIf(function () {
                    //         return this._id == null;
                    //     })
                    // ),
                    validReferrer: helpers.withMessage(
                        'invalid referrer',
                        function (value) {
                            return !this.viewMode &&
                                this.referrer_code &&
                                this.referrer_role == 'agent'
                                ? this.pl.agent_id != null
                                : true;
                        }
                    ),
                },
                player_id: {
                    validReferrer: helpers.withMessage(
                        'invalid referrer',
                        function (value) {
                            return !this.viewMode &&
                                this.referrer_code &&
                                this.referrer_role == 'player'
                                ? this.pl.player_id != null
                                : true;
                        }
                    ),
                },
                username: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    // maxLength: helpers.withMessage(
                    //     'maximum length is 32 characters',
                    //     maxLength(32)
                    // ),
                    available: helpers.withMessage(
                        'username already taken',
                        function (value) {
                            return !this.viewMode ? this.user_id == null : true;
                        }
                    ),
                },
                password: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this._id == null;
                        })
                    ),
                    // validPassword: helpers.withMessage(
                    //     'must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character',
                    //     validPassword
                    // ),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                    // alphaNumericOnly: helpers.withMessage(
                    //     'must consist of alphanumeric characters and numbers',
                    //     alphaNumericOnly
                    // ),
                },
                confirm_password: {
                    sameAs: helpers.withMessage(
                        `password doesn't match`,
                        sameAs(this.pl.password)
                    ),
                },
                pin: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this._id == null;
                        })
                    ),
                    minLength: helpers.withMessage(
                        'must consist of 4 characters',
                        minLength(4)
                    ),
                },
                name: {
                    // required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                email: {
                    email,
                    maxLength: helpers.withMessage(
                        'maximum length is 50 characters',
                        maxLength(50)
                    ),
                },
                mobile_number: {
                    minLength: helpers.withMessage(
                        'should be atleast 10 characters',
                        minLength(10)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 15 characters',
                        maxLength(15)
                    ),
                },
                player: {
                    level: {
                        required: helpers.withMessage('required', required),
                    },
                    type: {
                        required: helpers.withMessage('required', required),
                    },
                    class: {
                        required: helpers.withMessage('required', required),
                    },
                    behavior: {
                        required: helpers.withMessage('required', required),
                    },
                    commissions: {
                        losing: {
                            value: {
                                // requiredIf: helpers.withMessage(
                                //     'required',
                                //     requiredIf(function (value) {
                                //         if (
                                //             value == 0 &&
                                //             this.pl.player.commissions.losing
                                //                 .enabled
                                //         ) {
                                //             return required(value);
                                //         }
                                //         return true;
                                //     })
                                // ),
                                maxValueValue: maxValue(100),
                            },
                        },
                        minigames: {
                            powerball: {
                                single: {
                                    value: {
                                        // requiredIf: helpers.withMessage(
                                        //     'required',
                                        //     requiredIf(function (value) {
                                        //         if (
                                        //             value == 0 &&
                                        //             this.pl.player.commissions
                                        //                 .minigames.powerball
                                        //                 .single.enabled
                                        //         ) {
                                        //             return required(value);
                                        //         }
                                        //         return true;
                                        //     })
                                        // ),
                                        maxValueValue: maxValue(100),
                                    },
                                },
                                multiple: {
                                    single: {
                                        value: {
                                            // requiredIf: helpers.withMessage(
                                            //     'required',
                                            //     requiredIf(function (value) {
                                            //         if (
                                            //             value == 0 &&
                                            //             this.pl.player
                                            //                 .commissions
                                            //                 .minigames.powerball
                                            //                 .multiple.single
                                            //                 .enabled
                                            //         ) {
                                            //             return required(value);
                                            //         }
                                            //         return true;
                                            //     })
                                            // ),
                                            maxValueValue: maxValue(100),
                                        },
                                    },
                                    double: {
                                        value: {
                                            // requiredIf: helpers.withMessage(
                                            //     'required',
                                            //     requiredIf(function (value) {
                                            //         if (
                                            //             value == 0 &&
                                            //             this.pl.player
                                            //                 .commissions
                                            //                 .minigames.powerball
                                            //                 .multiple.double
                                            //                 .enabled
                                            //         ) {
                                            //             return required(value);
                                            //         }
                                            //         return true;
                                            //     })
                                            // ),
                                            maxValueValue: maxValue(100),
                                        },
                                    },
                                    triple: {
                                        value: {
                                            // requiredIf: helpers.withMessage(
                                            //     'required',
                                            //     requiredIf(function (value) {
                                            //         if (
                                            //             value == 0 &&
                                            //             this.pl.player
                                            //                 .commissions
                                            //                 .minigames.powerball
                                            //                 .multiple.triple
                                            //                 .enabled
                                            //         ) {
                                            //             return required(value);
                                            //         }
                                            //         return true;
                                            //     })
                                            // ),
                                            maxValueValue: maxValue(100),
                                        },
                                    },
                                },
                            },
                        },
                        casino: {
                            table: {
                                value: {
                                    // requiredIf: helpers.withMessage(
                                    //     'required',
                                    //     requiredIf(function (value) {
                                    //         if (
                                    //             value == 0 &&
                                    //             this.pl.player.commissions
                                    //                 .casino.table.enabled
                                    //         ) {
                                    //             return required(value);
                                    //         }
                                    //         return true;
                                    //     })
                                    // ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            slots: {
                                value: {
                                    // requiredIf: helpers.withMessage(
                                    //     'required',
                                    //     requiredIf(function (value) {
                                    //         if (
                                    //             value == 0 &&
                                    //             this.pl.player.commissions
                                    //                 .casino.slots.enabled
                                    //         ) {
                                    //             return required(value);
                                    //         }
                                    //         return true;
                                    //     })
                                    // ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            losing: {
                                value: {
                                    // requiredIf: helpers.withMessage(
                                    //     'required',
                                    //     requiredIf(function (value) {
                                    //         if (
                                    //             value == 0 &&
                                    //             this.pl.player.commissions
                                    //                 .casino.losing.enabled
                                    //         ) {
                                    //             return required(value);
                                    //         }
                                    //         return true;
                                    //     })
                                    // ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                        },
                        safety: {
                            table: {
                                value: {
                                    // requiredIf: helpers.withMessage(
                                    //     'required',
                                    //     requiredIf(function (value) {
                                    //         if (
                                    //             value == 0 &&
                                    //             this.pl.player.commissions
                                    //                 .safety.table.enabled
                                    //         ) {
                                    //             return required(value);
                                    //         }
                                    //         return true;
                                    //     })
                                    // ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            slots: {
                                value: {
                                    // requiredIf: helpers.withMessage(
                                    //     'required',
                                    //     requiredIf(function (value) {
                                    //         if (
                                    //             value == 0 &&
                                    //             this.pl.player.commissions
                                    //                 .safety.slots.enabled
                                    //         ) {
                                    //             return required(value);
                                    //         }
                                    //         return true;
                                    //     })
                                    // ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                        },
                    },
                    rebate: {
                        casino: {
                            enabled: false,
                            table: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.player.rebate.casino
                                                    .table.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            slots: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.player.rebate.casino
                                                    .slots.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                        },
                        sports: {
                            value: {
                                requiredIf: helpers.withMessage(
                                    'required',
                                    requiredIf(function (value) {
                                        if (
                                            value == 0 &&
                                            this.pl.player.rebate.sports.enabled
                                        ) {
                                            return required(value);
                                        }
                                        return true;
                                    })
                                ),
                                maxValueValue: maxValue(100),
                            },
                        },
                        minigames: {
                            value: {
                                requiredIf: helpers.withMessage(
                                    'required',
                                    requiredIf(function (value) {
                                        if (
                                            value == 0 &&
                                            this.pl.player.rebate.minigames
                                                .enabled
                                        ) {
                                            return required(value);
                                        }
                                        return true;
                                    })
                                ),
                                maxValueValue: maxValue(100),
                            },
                        },
                        losing: {
                            value: {
                                requiredIf: helpers.withMessage(
                                    'required',
                                    requiredIf(function (value) {
                                        if (
                                            value == 0 &&
                                            this.pl.player.rebate.losing.enabled
                                        ) {
                                            return required(value);
                                        }
                                        return true;
                                    })
                                ),
                                maxValueValue: maxValue(100),
                            },
                        },
                    },
                },
                customer_bank: {
                    physical: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                    virtual: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                },
                handler_bank: {
                    physical: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                    virtual: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                },
            },
            pl_points: {
                amount: {
                    required: helpers.withMessage('required', required),
                    minValueValue: helpers.withMessage('required', minValue(1)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
                // note: {
                //     required: helpers.withMessage('required', required),
                // },
            },
            pl_balance: {
                amount: {
                    required: helpers.withMessage('required', required),
                    minValueValue: helpers.withMessage('required', minValue(1)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
                // note: {
                //     required: helpers.withMessage('required', required),
                // },
            },
        };
    },
    computed: {
        route() {
            return this.$route;
        },
        viewMode() {
            return this._id != null;
        },
        forms() {
            var m_fd = this.formDataToObject(this.objectToFormData(this.pl));
            var o_fd = this.formDataToObject(
                this.objectToFormData(this.pl_origin)
            );
            var f_fd = new FormData();
            var rows = '';

            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];

                    if (!this.viewMode) {
                        // CREATE MODE
                        if (
                            !(m_fd[key] == null || m_fd[key] == '') &&
                            !f_fd.has(key)
                        ) {
                            f_fd.append(key, m_value);
                        }
                    } else {
                        // EDIT MODE

                        if (
                            m_fd[key] != o_fd[key] &&
                            ![
                                'agent[referral_code]',
                                'confirm_password',
                                'agent_id',
                                'note',
                            ].includes(key)
                        ) {
                            if (
                                [
                                    'handler_bank[physical][bank_name]',
                                    'handler_bank[physical][account_holder]',
                                    'handler_bank[physical][account_number]',
                                ].includes(key)
                            ) {
                                if (
                                    !f_fd.has(
                                        'handler_bank[physical][bank_name]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[physical][bank_name]',
                                        m_fd[
                                            'handler_bank[physical][bank_name]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[physical][account_holder]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[physical][account_holder]',
                                        m_fd[
                                            'handler_bank[physical][account_holder]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[physical][account_number]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[physical][account_number]',
                                        m_fd[
                                            'handler_bank[physical][account_number]'
                                        ] || ''
                                    );
                                }
                            } else if (
                                [
                                    'handler_bank[virtual][bank_name]',
                                    'handler_bank[virtual][account_holder]',
                                    'handler_bank[virtual][account_number]',
                                ].includes(key)
                            ) {
                                if (
                                    !f_fd.has(
                                        'handler_bank[virtual][bank_name]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[virtual][bank_name]',
                                        m_fd[
                                            'handler_bank[virtual][bank_name]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[virtual][account_holder]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[virtual][account_holder]',
                                        m_fd[
                                            'handler_bank[virtual][account_holder]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[virtual][account_number]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[virtual][account_number]',
                                        m_fd[
                                            'handler_bank[virtual][account_number]'
                                        ] || ''
                                    );
                                }
                            } else {
                                f_fd.append(key, m_value);
                                if (
                                    key ==
                                        'player[commissions][casino][table][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][casino][table][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'player[commissions][casino][slots][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][casino][slots][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'player[commissions][casino][losing][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][casino][losing][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'player[commissions][safety][table][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][safety][table][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'player[commissions][safety][slots][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][safety][slots][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'player[commissions][minigames][powerball][single][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][minigames][powerball][single][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'player[commissions][minigames][powerball][multiple][single][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][minigames][powerball][multiple][single][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'player[commissions][minigames][powerball][multiple][double][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][minigames][powerball][multiple][double][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'player[commissions][minigames][powerball][multiple][triple][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'player[commissions][minigames][powerball][multiple][triple][value]',
                                        0
                                    );
                                }
                            }
                            rows += `<tr>
                                    <td>${this.$t(this.arrayToArrows(key))}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key] || ''
                                        }
                                    </td>
                                    <td class="${
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key] || ''
                                        }
                                    </td>
                                </tr>`;
                        } else if (
                            [
                                'player[commissions][enabled]',
                                'player[commissions][type]',
                                'player[commissions][losing][enabled]',
                                'player[commissions][losing][value]',
                                'player[commissions][minigames][powerball][type]',
                                'player[commissions][minigames][powerball][enabled]',
                                'player[commissions][minigames][powerball][single][value]',
                                'player[commissions][minigames][powerball][single][enabled]',
                                'player[commissions][minigames][powerball][multiple][enabled]',
                                'player[commissions][minigames][powerball][multiple][single][value]',
                                'player[commissions][minigames][powerball][multiple][single][enabled]',
                                'player[commissions][minigames][powerball][multiple][double][value]',
                                'player[commissions][minigames][powerball][multiple][double][enabled]',
                                'player[commissions][minigames][powerball][multiple][triple][enabled]',
                                'player[commissions][minigames][powerball][multiple][triple][value]',
                                'player[commissions][casino][table][enabled]',
                                'player[commissions][casino][table][value]',
                                'player[commissions][casino][slots][enabled]',
                                'player[commissions][casino][slots][value]',
                                'player[commissions][casino][losing][enabled]',
                                'player[commissions][casino][losing][value]',
                                'player[commissions][safety][table][enabled]',
                                'player[commissions][safety][table][value]',
                                'player[commissions][safety][slots][enabled]',
                                'player[commissions][safety][slots][value]',
                            ].includes(key)
                        ) {
                            f_fd.append(key, m_value);
                        }
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    watch: {
        referrer_ids: {
            async handler(agents) {
                var id = null;
                agents.forEach((agent, i) => {
                    if (agent != null) {
                        id = agent._id;
                    }
                });
                this.pl.agent_id = id;
            },
            deep: true,
        },
        deposit_filters: {
            handler() {
                this.initDeposit(1);
            },
            deep: true,
        },
        withdraw_filters: {
            handler() {
                this.initWithdraw(1);
            },
            deep: true,
        },
        activity_log_filters: {
            handler() {
                this.initActivityLogs(1);
            },
            deep: true,
        },
        point_log_filters: {
            handler() {
                this.initPointLogs(1);
            },
            deep: true,
        },
        bet_filters: {
            handler() {
                this.initBet(1);
            },
            deep: true,
        },
        'pl.handler_bank.physical.default'(isDefault) {
            if (isDefault) {
                // this.pl.handler_bank.physical.enabled = true
                this.pl.handler_bank.virtual.default = false;
                // this.pl.handler_bank.virtual.enabled = false
                // this.pl.handler_bank.virtual.bank_name = null
                // this.pl.handler_bank.virtual.account_holder = null
                // this.pl.handler_bank.virtual.account_number = null
            }
        },
        'pl.handler_bank.virtual.default'(isDefault) {
            if (isDefault) {
                // this.pl.handler_bank.virtual.enabled = true
                this.pl.handler_bank.physical.default = false;
                // this.pl.handler_bank.physical.enabled = false
                // this.pl.handler_bank.physical.bank_name = null
                // this.pl.handler_bank.physical.account_holder = null
                // this.pl.handler_bank.physical.account_number = null
            }
        },
        // referrer_code(value) {
        //     this.getReferrer();
        // },
        // 'pl.username'() {
        //     this.getUsernames();
        // },
    },
    methods: {
        ...mapActions('player', {
            playerGetList: 'getList',
            playerCreate: 'create',
            playerUpdate: 'update',
            playerView: 'view',
            playerViewPassword: 'viewPassword',
            playerUpdateAssets: 'updateAssets',
            playerUpdateRole: 'updateRole',
        }),
        ...mapActions('agent', {
            agentGetFilterList: 'getFilterList',
        }),
        ...mapActions('referrer', {
            referrerView: 'view',
        }),
        ...mapActions('transaction', {
            transactionGetList: 'getList',
        }),
        ...mapActions('log', {
            assetGetList: 'getList',
        }),
        ...mapActions('bet', {
            betGetList: 'getList',
        }),
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var swal_html = `
                        <table class="table table-sm table-bordered fs-6">
                            <thead>
                                <tr>
                                    <th>${this.$t('field')}</th>
                                    <th>${this.$t('before')}</th>
                                    <th>${this.$t('after')}</th>
                                </tr>
                            </thead>
                            <tbody>${this.forms.changes}</tbody>
                        </table>
                    `;
                if (this.viewMode && !this.forms.changes) {
                    Swal.fire({
                        icon: 'info',
                        title: this.$t('no changes made'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }

                const confirmed = await Swal.fire({
                    title: this.viewMode
                        ? this.$t('verify changes')
                        : `${this.$t('create new player')}`,
                    // html: this.forms.changes ? swal_html : '',
                    icon: this.viewMode ? 'info' : 'warning',
                    // width: this.viewMode ? 800 : null,
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    const res = !this.viewMode
                        ? await this.playerCreate(this.forms.form_data)
                        : await this.playerUpdate({
                              form_data: this.forms.x_www_form_urlencoded,
                              id: this._id,
                          });

                    if (res) {
                        if (this.viewMode) {
                            if (res.success) this.getDetails();
                            this.pl.password = null;
                            this.pl.confirm_password = null;
                            this.pl.pin = null;
                        } else {
                            if (res.success) {
                                this.reset();
                            }
                        }

                        var title = {
                            create: res.success
                                ? this.$t('new account has been created.')
                                : this.$t('creating failed'),
                            update: res.success
                                ? this.$t('player account has been updated')
                                : this.$t('updating failed'),
                        };

                        var field_rows = ``;
                        var errors =
                            !res.success && res.data.errors
                                ? this.convertObjectToArray(res.data.errors)
                                : [];
                        errors.forEach((e) => {
                            var field_value_rows = ``;
                            e.errors.forEach((err) => {
                                field_value_rows += `<div class="text-danger">${err.toLowerCase()}</div>`;
                            });
                            field_rows += `
                            <tr>
                                <td>${this.$t(e.field)}</td>
                                <td>${field_value_rows}</td>
                            </tr>
                            `;
                        });

                        var errors_html = `
                            <table class="table table-sm table-bordered fs-6">
                                <thead>
                                    <tr>
                                        <th>${this.$t('field')}</th>
                                        <th>${this.$t('validation')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${field_rows}
                                </tbody>
                            </table>
                        `;
                        if (!res.success && errors.length <= 0) {
                            errors_html = res.data.message;
                        }
                        if (res.success) {
                            window.opener.postMessage('reload-players');
                        }

                        Swal.fire({
                            title: this.viewMode ? title.update : title.create,
                            html: res.success ? '' : errors_html,
                            icon: res.success ? 'success' : 'error',
                            // width: res.success && !this.viewMode ? null : 800,
                            timer: res.success ? 2000 : null,
                            confirmButtonText: this.$t('ok'),
                            // cancelButtonColor: "#74788d",
                            // cancelButtonText: this.$t("cancel"),
                        });
                    }
                }
            }
        },
        async getDetails() {
            if (!this._id) {
                // this.pl_origin = JSON.parse(JSON.stringify(this.pl))
                return;
            }
            this.submitted = false;
            this.loading = true;
            const data = await this.playerView(this._id);
            this.loading = false;
            // this.pl.client_id = data.client_id
            // this.pl.agent_id = data.agent_id
            this.pl.agent_id = data.agent_id || null;
            this.pl.player_id = data.player_id || null;
            this.pl.type = data.type;
            this.pl.created_at = data.created_at;
            this.pl.username = data.username;
            this.pl.status = data.status;
            this.pl.name = data.name;
            // this.pl.nickname = data.nickname
            this.pl.email = data.email || null;
            this.pl.mobile_number = data.mobile_number || null;

            this.pl.player.commissions.type = data.player.commissions.type;
            this.pl.player.commissions.enabled = Boolean(
                data.player.commissions.enabled
            );

            this.pl.player.commissions.losing.value =
                data.player.commissions.losing.value;
            this.pl.player.commissions.losing.enabled =
                data.player.commissions.losing.enabled || false;
            this.pl.player.commissions.minigames.powerball.type =
                data.player.commissions.minigames.powerball.type;
            this.pl.player.commissions.minigames.powerball.enabled =
                data.player.commissions.minigames.powerball.enabled;
            this.pl.player.commissions.minigames.powerball.single.value =
                data.player.commissions.minigames.powerball.single.value;
            this.pl.player.commissions.minigames.powerball.single.enabled =
                data.player.commissions.minigames.powerball.single.enabled;
            this.pl.player.commissions.minigames.powerball.multiple.single.value =
                data.player.commissions.minigames.powerball.multiple.single.value;
            this.pl.player.commissions.minigames.powerball.multiple.single.enabled =
                data.player.commissions.minigames.powerball.multiple.single.enabled;
            this.pl.player.commissions.minigames.powerball.multiple.double.value =
                data.player.commissions.minigames.powerball.multiple.double.value;
            this.pl.player.commissions.minigames.powerball.multiple.double.enabled =
                data.player.commissions.minigames.powerball.multiple.double.enabled;
            this.pl.player.commissions.minigames.powerball.multiple.triple.value =
                data.player.commissions.minigames.powerball.multiple.triple.value;
            this.pl.player.commissions.minigames.powerball.multiple.triple.enabled =
                data.player.commissions.minigames.powerball.multiple.triple.enabled;
            this.pl.player.commissions.casino.table.value =
                data.player.commissions.casino.table.value;
            this.pl.player.commissions.casino.table.enabled =
                data.player.commissions.casino.table.enabled;
            this.pl.player.commissions.casino.slots.value =
                data.player.commissions.casino.slots.value;
            this.pl.player.commissions.casino.slots.enabled =
                data.player.commissions.casino.slots.enabled;
            this.pl.player.commissions.casino.losing.value =
                data.player.commissions.casino.losing.value;
            this.pl.player.commissions.casino.losing.enabled =
                data.player.commissions.casino.losing.enabled;
            this.pl.player.commissions.safety.table.value =
                data.player.commissions.safety.table.value;
            this.pl.player.commissions.safety.table.enabled =
                data.player.commissions.safety.table.enabled;
            this.pl.player.commissions.safety.slots.value =
                data.player.commissions.safety.slots.value;
            this.pl.player.commissions.safety.slots.enabled =
                data.player.commissions.safety.slots.enabled;

            this.pl.player.rebate.casino.enabled =
                data.player.rebate.casino.enabled;
            this.pl.player.rebate.casino.table.value =
                data.player.rebate.casino.table.value;
            this.pl.player.rebate.casino.table.enabled =
                data.player.rebate.casino.table.enabled;
            this.pl.player.rebate.casino.slots.value =
                data.player.rebate.casino.slots.value;
            this.pl.player.rebate.casino.slots.enabled =
                data.player.rebate.casino.slots.enabled;
            this.pl.player.rebate.sports.enabled =
                data.player.rebate.sports.enabled;
            this.pl.player.rebate.sports.value =
                data.player.rebate.sports.value;
            this.pl.player.rebate.minigames.enabled =
                data.player.rebate.minigames.enabled;
            this.pl.player.rebate.minigames.value =
                data.player.rebate.minigames.value;
            this.pl.player.rebate.losing.enabled =
                data.player.rebate.losing.enabled;
            this.pl.player.rebate.losing.value =
                data.player.rebate.losing.value;

            this.pl.player.level = data.player.level;
            this.pl.player.type = data.player.type;
            this.pl.player.class = data.player.class;
            this.pl.player.behavior = data.player.behavior;

            this.pl.player.assets.balance = data.player.assets.balance;
            this.pl.player.assets.points = data.player.assets.points;

            if (data.agent_referrer) {
                this.pl.agent_referrer.name = data.agent_referrer.name || null;
                this.pl.agent_referrer.username =
                    data.agent_referrer.username || null;
                this.pl.agent_referrer.agent.level =
                    data.agent_referrer.agent.level || null;
            }
            if (data.player_referrer) {
                this.pl.player_referrer.name =
                    data.player_referrer.name || null;
                this.pl.player_referrer.username =
                    data.player_referrer.username || null;
                data.player_referrer.name || null;
                this.pl.player_referrer.player.level =
                    data.player_referrer.player.level || null;
            }

            this.pl.handler_bank.physical.enabled =
                data.handler_bank.physical.enabled || false;
            this.pl.handler_bank.physical.default =
                data.handler_bank.physical.default || false;
            this.pl.handler_bank.physical.bank_name =
                data.handler_bank.physical.bank_name || null;
            this.pl.handler_bank.physical.account_holder =
                data.handler_bank.physical.account_holder || null;
            this.pl.handler_bank.physical.account_number =
                data.handler_bank.physical.account_number || null;

            this.pl.handler_bank.virtual.enabled =
                data.handler_bank.virtual.enabled || false;
            this.pl.handler_bank.virtual.default =
                data.handler_bank.virtual.default || false;
            this.pl.handler_bank.virtual.bank_name =
                data.handler_bank.virtual.bank_name || null;
            this.pl.handler_bank.virtual.account_holder =
                data.handler_bank.virtual.account_holder || null;
            this.pl.handler_bank.virtual.account_number =
                data.handler_bank.virtual.account_number || null;
            this.pl.agent_upline = data.agent_upline;
            this.pl_origin = {
                ...this.pl_origin,
                ...JSON.parse(JSON.stringify(data)),
            };

            if (!Boolean(data.player.commissions.enabled)) {
                this.pl_origin.player.commissions.enabled = false;
            }
            // this.pl_origin.agent.referral_code = '';
            // this.pl_origin = {
            //     ...pl_origin,
            //     ...JSON.parse(JSON.stringify(data)),
            // };

            this.initDeposit(1);
            this.initWithdraw(1);
            this.initPointLogs(1);
            this.initActivityLogs(1);
            this.initBet(1);
        },
        async getPassword() {
            if (!this.isAuthorized()) return;
            if (!this._id) {
                return;
            }
            const data = await this.playerViewPassword(this._id);
            this.pl_origin.password = data.raw_password;
            this.pl.password = data.raw_password;
        },
        async getPin() {
            if (!this.isAuthorized()) return;
            if (!this._id) {
                return;
            }
            const data = await this.playerViewPassword(this._id);
            this.pl_origin.pin = data.raw_pin;
            this.pl.pin = data.raw_pin || null;
        },
        async getReferrer() {
            this.v$.pl.agent_id.$touch();
            this.pl.agent_id = null;
            this.v$.pl.player_id.$touch();
            this.referrer_role = null;
            this.referrer_type = null;
            this.pl.player_id = null;
            this.pl.agent_referrer.username = null;
            this.pl.agent_referrer.role = null;
            this.pl.player_referrer.username = null;
            this.pl.player_referrer.role = null;
            // if (!this.referrer_code) return;
            if (this.referrer_code == null || this.referrer_code == '') return;

            const referrer = await this.referrerView(this.referrer_code);
            if (referrer) {
                // this.pl.agent_id = referrer._id;
                this.pl.type = referrer.type;
                this.pl[`${referrer.role}_id`] = referrer._id;
                this.referrer_role = referrer.role;
                this.referrer_type = referrer.type;
                this.pl[`${referrer.role}_referrer`].role = referrer.role;
                this.pl[`${referrer.role}_referrer`].username =
                    referrer.username;
            }
        },
        async getUsernames() {
            this.v$.pl.username.$touch();
            this.user_id = null;
            var pl = {
                username: this.pl.username || null,
            };
            const users = await this.playerGetList(pl);
            if (users.data.length === 1) {
                this.user_id = users.data[0]._id;
            }
        },
        async reset() {
            this.submitted = false;
            this.pl.client_id = null;
            this.pl.agent_id = null;
            this.pl.player_id = null;
            this.pl.type = 'offline';
            this.pl.username = null;
            this.pl.status = 'active';
            this.pl.password = null;
            this.pl.confirm_password = null;
            this.pl.pin = null;
            this.pl.name = null;

            this.pl.player.commissions.type = 'bet';
            this.pl.player.commissions.enabled = true;
            this.pl.player.commissions.losing.value = 0;
            this.pl.player.commissions.losing.enabled = false;
            this.pl.player.commissions.minigames.powerball.type = 'single';
            this.pl.player.commissions.minigames.powerball.enabled = false;
            this.pl.player.commissions.minigames.powerball.single.value = 0;
            this.pl.player.commissions.minigames.powerball.single.enabled = false;
            this.pl.player.commissions.minigames.powerball.multiple.single.value = 0;
            this.pl.player.commissions.minigames.powerball.multiple.single.enabled = false;
            this.pl.player.commissions.minigames.powerball.multiple.double.value = 0;
            this.pl.player.commissions.minigames.powerball.multiple.double.enabled = false;
            this.pl.player.commissions.minigames.powerball.multiple.triple.value = 0;
            this.pl.player.commissions.minigames.powerball.multiple.triple.enabled = false;
            this.pl.player.commissions.casino.table.value = 0;
            this.pl.player.commissions.casino.table.enabled = false;
            this.pl.player.commissions.casino.slots.value = 0;
            this.pl.player.commissions.casino.slots.enabled = false;
            this.pl.player.commissions.casino.losing.value = 0;
            this.pl.player.commissions.casino.losing.enabled = false;
            this.pl.player.commissions.safety.table.value = 0;
            this.pl.player.commissions.safety.table.enabled = false;
            this.pl.player.commissions.safety.slots.value = 0;
            this.pl.player.commissions.safety.slots.enabled = false;

            this.pl.player.rebate.casino.enabled = false;
            this.pl.player.rebate.casino.table.enabled = false;
            this.pl.player.rebate.casino.table.value = 0;
            this.pl.player.rebate.casino.slots.enabled = false;
            this.pl.player.rebate.casino.slots.value = 0;
            this.pl.player.rebate.sports.enabled = false;
            this.pl.player.rebate.sports.value = 0;
            this.pl.player.rebate.minigames.enabled = false;
            this.pl.player.rebate.minigames.value = 0;
            this.pl.player.rebate.losing.enabled = false;
            this.pl.player.rebate.losing.value = 0;

            this.pl.email = null;
            this.pl.mobile_number = null;
            this.pl.agent.referral_code = null;
            this.pl.player.type = 'normal';
            this.pl.player.class = 'normal';
            this.pl.player.behavior = 'normal';

            this.pl.handler_bank.physical.bank_name = null;
            this.pl.handler_bank.physical.account_holder = null;
            this.pl.handler_bank.physical.account_number = null;

            this.pl.handler_bank.virtual.bank_name = null;
            this.pl.handler_bank.virtual.account_holder = null;
            this.pl.handler_bank.virtual.account_number = null;

            this.pl.agent_id = null;
            this.pl.player_id = null;
            this.pl.username = null;
            this.referrer_code = null;
            this.v$.pl.agent_id.$reset();
            this.v$.pl.player_id.$reset();
            this.v$.pl.username.$reset();
            this.v$.pl.$reset();
            this.handleClear(0);
        },
        async submitAsset(asset, action) {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$[`pl_${asset}`].$touch();
            if (this.v$[`pl_${asset}`].$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure')}?`,
                    html: `<table class="table table-bordered table-sm ">
                        <tbody>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'action'
                                )}</th>
                                <td>${this.$t(action)} ${this.numberString(
                        this[`pl_${asset}`].amount
                    )} ${this.$t(asset)}</td>
                            </tr>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'username'
                                )}</th>
                                <td>${this.pl.username}</td>
                            </tr>
                        </tbody>
                    </table>`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('amount', this[`pl_${asset}`].amount);
                    // fd.append('note', this[`pl_${asset}`].note);

                    this[`pl_${asset}`].loading = true;
                    const res = await this.playerUpdateAssets({
                        id: this._id,
                        asset: asset,
                        action: action,
                        form_data: fd,
                    });
                    this[`pl_${asset}`].loading = false;
                    if (res) {
                        Swal.fire({
                            html: this.$t(res.data.message.toLowerCase()),
                            icon: res.success ? 'success' : 'error',
                            timer: res.success ? 2000 : null,
                        });
                        this.getDetails();
                        this[`pl_${asset}`].amount = 0;
                        this[`pl_${asset}`].note = null;
                    }
                }
            }
        },
        async initDeposit(p) {
            var pl = {
                user: 'player',
                type: 'deposit',
                username: this.pl.username,
                from: this.dateYmD(this.deposit_filters.from) + ' 00:00:00',
                to: this.dateYmD(this.deposit_filters.to) + ' 23:59:59',
                online: 'all',
                limit: 6,
                page_no: p ? p : 1,
            };
            this.deposit_loading = true;
            const data = await this.transactionGetList(pl);
            this.deposit_data.list = data.data;
            this.deposit_data.links = data.links;
            this.deposit_data.current_page = data.current_page;
            this.deposit_data.per_page = data.per_page;
            this.deposit_data.last_page = data.last_page;
            this.deposit_loading = false;
        },
        async initWithdraw(p) {
            var pl = {
                user: 'player',
                type: 'withdraw',
                username: this.pl.username,
                from: this.dateYmD(this.withdraw_filters.from) + ' 00:00:00',
                to: this.dateYmD(this.withdraw_filters.to) + ' 23:59:59',
                online: 'all',
                limit: 6,
                page_no: p ? p : 1,
            };
            this.withdraw_loading = true;
            const data = await this.transactionGetList(pl);
            this.withdraw_data.list = data.data;
            this.withdraw_data.links = data.links;
            this.withdraw_data.current_page = data.current_page;
            this.withdraw_data.per_page = data.per_page;
            this.withdraw_data.last_page = data.last_page;
            this.withdraw_loading = false;
        },
        async initPointLogs(p) {
            var pl = {
                log_type: 'asset',
                column: 'points',
                role: 'player',
                username: this.pl.username,
                from: this.dateYmD(this.point_log_filters.from) + ' 00:00:00',
                to: this.dateYmD(this.point_log_filters.to) + ' 23:59:59',
                online: true,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.point_log_loading = true;
            const data = await this.assetGetList(pl);
            this.point_log_data.list = data.data;
            this.point_log_data.links = data.links;
            this.point_log_data.current_page = data.current_page;
            this.point_log_data.per_page = data.per_page;
            this.point_log_data.last_page = data.last_page;
            this.point_log_loading = false;
        },
        async initActivityLogs(p) {
            var pl = {
                log_type: 'activity',
                role: 'player',
                username: this.pl.username,
                from:
                    this.dateYmD(this.activity_log_filters.from) + ' 00:00:00',
                to: this.dateYmD(this.activity_log_filters.to) + ' 23:59:59',
                online: true,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.activity_log_loading = true;
            const data = await this.assetGetList(pl);
            this.activity_log_data.list = data.data;
            this.activity_log_data.links = data.links;
            this.activity_log_data.current_page = data.current_page;
            this.activity_log_data.per_page = data.per_page;
            this.activity_log_data.last_page = data.last_page;
            this.activity_log_loading = false;
        },
        async initBet(p) {
            var pl = {
                username: this.pl.username,
                from: this.dateYmD(this.bet_filters.from) + ' 00:00:00',
                to: this.dateYmD(this.bet_filters.to) + ' 23:59:59',
                game: this.bet_filters.game || null,
                limit: 6,
                live: false,
                page_no: p ? p : 1,
            };
            this.bet_loading = true;
            const data = await this.betGetList(pl);
            this.bet_data.list = data.data.data;
            this.bet_data.links = data.data.links;
            this.bet_data.current_page = data.data.current_page;
            this.bet_data.per_page = data.data.per_page;
            this.bet_data.last_page = data.data.last_page;
            this.bet_loading = false;
        },
        handleFilter(value) {
            if (!value) return;
            var startIndex = value.option_index + 1;
            var loop = this.agents_options.length;
            if (startIndex < loop) {
                for (var i = startIndex; i < loop; i++) {
                    this.agents_options.splice(startIndex, 1);
                    this.referrer_ids[i] = null;
                }
            }
            this.initFilter(value._id);
        },
        handleClear(index) {
            var loop = this.agents_options.length;
            var startIndex = index + 1;
            for (var i = startIndex; i < loop; i++) {
                this.agents_options.splice(startIndex, 1);
                this.referrer_ids[i] = null;
            }
            if (index == 0) this.referrer_ids[0] = null;
        },
        async initFilter(referrer_id) {
            const data = await this.agentGetFilterList({
                referrer: referrer_id || null,
            });
            var agents = [];
            data.forEach((agent) => {
                agent.option_index = this.agents_options.length;
                agents.push({
                    label: agent.username,
                    value: agent,
                });
            });
            // this.referrer_ids.push()
            if (agents.length) {
                this.agents_options.push(agents);
            }
        },
        async convertToAgent() {
            if (!this.isAuthorized()) return;
            var swal_html = `
                         <div>${this.$t('convert to agent')}</div>
                         <div>
                            <i class="mdi mdi-account-cowboy-hat text-primary fs-2"></i>
                            <i class="bx bx-chevrons-right bx-fade-right fs-3"></i>
                            <i class="mdi mdi-account-tie bx-flashing text-danger fs-2"></i>
                        </div>
                        <table class="table table-sm table-bordered fs-6 mt-1">
                            <tbody>
                                    <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i class="mdi ${
                                                this.pl.type == 'online'
                                                    ? 'mdi-web text-info'
                                                    : this.pl.type == 'offline'
                                                    ? 'mdi-storefront text-success'
                                                    : ''
                                            } fs-5"></i>
                                            <i class="mdi mdi-numeric-${
                                                this.pl.player.level
                                            }-box fs-4  level-${
                this.pl.player.level
            }"></i>
                                            ${this.pl.username}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.playerUpdateRole({
                    id: this.$route.query.id,
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: this.$t(res.data.message),
                        confirmButtonText: this.$t('ok'),
                    });
                    if (res.success) {
                        this.$router.replace({
                            path: '/agent/form',
                            query: { id: this.$route.query.id },
                        });
                    }
                }
            }
        },
    },
    mounted() {
        this.initFilter();
        this.getDetails();
    },
};
</script>
